import React, { ReactElement } from "react";

import Image from "next/image";

export default function AboutUS(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row lg:gap-32 gap-16 lg:mb-52 mb-20 lg:mt-28 mt-14 ">
            <div className=" max-w-[575px] mx-auto lg:mx-0">
                <h2 className="font-bold lg:text-3xl text-xl lg:leading-[45px] text-[#1D2458] ">
                    Who Is a Home Warranty For?
                </h2>
                <p className="mt-5 font-light lg:text-lg  text-base lg:leading-7">
                    A home warranty is a service that can help protect household
                    appliances and systems from unexpected repairs and
                    breakdowns. It's especially helpful for homeowners who are
                    not skilled in repairs or don't have the time to handle
                    maintenance issues. Additionally, a home warranty can
                    provide homeowners with peace of mind, knowing their home
                    systems are covered in case of unexpected breakdowns.
                </p>
                <span className="block w-[173px] h-[6px] bg-[#24317C] mt-6"></span>
            </div>
            <div className="w-[280px] h-[200px] md:w-[447px] md:min-w-[447px] md:h-[358px]  relative  mx-auto lg:mx-0">
                <Image
                    src={"/homewarranty/assets/images/aboutUsImg.png"}
                    alt="Image"
                    layout="fill"
                />
            </div>
        </section>
    );
}
